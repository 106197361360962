import fetch from 'node-fetch';
import { Cookies } from 'react-cookie';
import { keys } from '../config/keys';

import {
  CREATE_PROXY_USER_REQUEST,
  CREATE_PROXY_USER_SUCCESS,
  CREATE_PROXY_USER_FAILURE,

  UPDATE_PROXY_REQUEST,
  UPDATE_PROXY_SUCCESS,
  UPDATE_PROXY_FAILURE,

  BATCH_INVITE_PROXY_USER_REQUEST,
  BATCH_INVITE_PROXY_USER_SUCCESS,
  BATCH_INVITE_PROXY_USER_FAILURE,

  REINVITE_PROXY_USER_REQUEST,
  REINVITE_PROXY_USER_SUCCESS,
  REINVITE_PROXY_USER_FAILURE,

  ARCHIVE_PROXY_USER_REQUEST,
  ARCHIVE_PROXY_USER_SUCCESS,
  ARCHIVE_PROXY_USER_FAILURE,

  CREATE_PROXY_CONFIGURATION_REQUEST,
  CREATE_PROXY_CONFIGURATION_SUCCESS,
  CREATE_PROXY_CONFIGURATION_FAILURE,

  UPDATE_PROXY_CONFIGURATION_REQUEST,
  UPDATE_PROXY_CONFIGURATION_SUCCESS,
  UPDATE_PROXY_CONFIGURATION_FAILURE,

  FETCH_PROXY_CONFIGURATION_REQUEST,
  FETCH_PROXY_CONFIGURATION_SUCCESS,
  FETCH_PROXY_CONFIGURATION_FAILURE,

  FETCH_PROXY_USERS_REQUEST,
  FETCH_PROXY_USERS_SUCCESS,
  FETCH_PROXY_USERS_FAILURE,

  FETCH_TRAVELER_PROXY_USERS_REQUEST,
  FETCH_TRAVELER_PROXY_USERS_SUCCESS,
  FETCH_TRAVELER_PROXY_USERS_FAILURE,

  ADD_EXISTING_PROXY_REQUEST,
  ADD_EXISTING_PROXY_SUCCESS,
  ADD_EXISTING_PROXY_FAILURE,

  REMOVE_PROXY_ACCESS_REQUEST,
  REMOVE_PROXY_ACCESS_SUCCESS,
  REMOVE_PROXY_ACCESS_FAILURE,

  AUTHORIZED_TRAVELERS_REQUEST,
  AUTHORIZED_TRAVELERS_SUCCESS,
  AUTHORIZED_TRAVELERS_FAILURE,

  FETCH_PROXY_PLANS_REQUEST,
  FETCH_PROXY_PLANS_SUCCESS,
  FETCH_PROXY_PLANS_FAILURE
} from "./types";

const fetchWithDispatch = (url, method, dispatch, requestType, successType, failureType, data = null, callback = null, apiEndpoint="") => {
  const cookies = new Cookies();
  const token = cookies.get('token');

  dispatch({ type: requestType });

  fetch(`${keys.baseUri}${url}`, {
    method,
    headers: token,
    body: data ? JSON.stringify(data) : null,
  })
    .then(res => res.json().then(jsonData => ({ status: res.status, data: jsonData })))
    .then(({ status, data }) => {
      if (status === 200 || status === 201) {
        if (callback && typeof callback === "function") {
          if(apiEndpoint==="batchInviteProxyUser") {
            callback(true, 'Success', data); 
          } else {
            callback(true, 'Success');
          }
        }
        dispatch({ type: successType, payload: data });
      } else {
        if (callback && typeof callback === "function") {
          callback(false, data.message || 'Request failed');
        }
        dispatch({ type: failureType, payload: data.message || 'Error' });
      }
    })
    .catch(error => {
      if (callback && typeof callback === "function") {
        callback(false, error.toString());
      }
      dispatch({ type: failureType, payload: error.toString() });
    });
};

export const createProxyUser = (data,type="client", callback) => dispatch => {
  let url = `/api/${type}/proxy_user_invitations/invite_single`;
  if(type==='traveler')  {
    url = `/api/traveler/proxy_users/invite_new`;
  }

  fetchWithDispatch(
    url,
    'POST',
    dispatch,
    CREATE_PROXY_USER_REQUEST,
    CREATE_PROXY_USER_SUCCESS,
    CREATE_PROXY_USER_FAILURE,
    data,
    callback
  );
};

export const updateProxy = (userId, data, callback) => dispatch => {
  fetchWithDispatch(`/api/client/proxy_users/${userId}`,
    'PUT',
    dispatch,
    UPDATE_PROXY_REQUEST,
    UPDATE_PROXY_SUCCESS,
    UPDATE_PROXY_FAILURE,
    data,
    callback
  );
};

export const batchInviteProxyUser = (data, callback) => dispatch => {
  fetchWithDispatch(
    '/api/client/proxy_user_invitations/batch_invite',
    'POST',
    dispatch,
    BATCH_INVITE_PROXY_USER_REQUEST,
    BATCH_INVITE_PROXY_USER_SUCCESS,
    BATCH_INVITE_PROXY_USER_FAILURE,
    data,
    callback,
    "batchInviteProxyUser"
  );
};

export const reinviteProxyUser = (data, callback) => dispatch => {
  fetchWithDispatch(
    `/api/client/proxy_user_invitations/resend_invitation`,
    'POST',
    dispatch,
    REINVITE_PROXY_USER_REQUEST,
    REINVITE_PROXY_USER_SUCCESS,
    REINVITE_PROXY_USER_FAILURE,
    data,
    callback
  );
};

export const archiveProxyUser = (userId, callback) => dispatch => {
  fetchWithDispatch(
    `/api/client/proxy_users/${userId}/archive`,
    'PATCH',
    dispatch,
    ARCHIVE_PROXY_USER_REQUEST,
    ARCHIVE_PROXY_USER_SUCCESS,
    ARCHIVE_PROXY_USER_FAILURE,
    null,
    callback
  );
};

export const unArchiveProxyUser = (userId, callback) => dispatch => {
  fetchWithDispatch(
    `/api/client/proxy_users/${userId}/unarchive`,
    'PATCH',
    dispatch,
    ARCHIVE_PROXY_USER_REQUEST,
    ARCHIVE_PROXY_USER_SUCCESS,
    ARCHIVE_PROXY_USER_FAILURE,
    null,
    callback
  );
};


export const createProxyConfiguration = (data, callback) => dispatch => {
  fetchWithDispatch(
    '/api/client/proxy_management_settings',
    'POST',
    dispatch,
    CREATE_PROXY_CONFIGURATION_REQUEST,
    CREATE_PROXY_CONFIGURATION_SUCCESS,
    CREATE_PROXY_CONFIGURATION_FAILURE,
    data,
    callback
  );
};

export const updateProxyConfiguration = (configId, data, callback) => dispatch => {
  fetchWithDispatch(
    `/api/client/proxy_management_settings/${configId}`,
    'PATCH',
    dispatch,
    UPDATE_PROXY_CONFIGURATION_REQUEST,
    UPDATE_PROXY_CONFIGURATION_SUCCESS,
    UPDATE_PROXY_CONFIGURATION_FAILURE,
    data,
    callback
  );
};

export const fetchProxyConfiguration = (configId) => dispatch => {
  fetchWithDispatch(
`/api/client/proxy_management_settings/${configId}`,
    'GET',
    dispatch,
    FETCH_PROXY_CONFIGURATION_REQUEST,
    FETCH_PROXY_CONFIGURATION_SUCCESS,
    FETCH_PROXY_CONFIGURATION_FAILURE,
    null
   );
};

export const fetchProxyUsers = (callback) => dispatch => {
  fetchWithDispatch(
    '/api/client/proxy_management_settings/proxy_users',
    'GET',
    dispatch,
    FETCH_PROXY_USERS_REQUEST,
    FETCH_PROXY_USERS_SUCCESS,
    FETCH_PROXY_USERS_FAILURE,
    null,
    callback
  );
};



export const fetchTravelerProxyUsers = (callback) => dispatch => {

  fetchWithDispatch(
    '/api/traveler/proxy_users',
    'GET',
    dispatch,
    FETCH_TRAVELER_PROXY_USERS_REQUEST,
    FETCH_TRAVELER_PROXY_USERS_SUCCESS,
    FETCH_TRAVELER_PROXY_USERS_FAILURE,
    null,
    callback
  );
};


export const travelerAddExistingProxy = (data, callback) => dispatch => {

  fetchWithDispatch(
    '/api/traveler/proxy_users/add_existing',
    'POST',
    dispatch,
    ADD_EXISTING_PROXY_REQUEST,
    ADD_EXISTING_PROXY_SUCCESS,
    ADD_EXISTING_PROXY_FAILURE,
    data,
    callback
  );
};



export const removeProxyAccess = (callback) => dispatch => {

  fetchWithDispatch(
    '/api/traveler/proxy_users/remove_proxy',
    'POST',
    dispatch,
    REMOVE_PROXY_ACCESS_REQUEST,
    REMOVE_PROXY_ACCESS_SUCCESS,
    REMOVE_PROXY_ACCESS_FAILURE,
    null,
    callback
  );
};



export const getAuthorizedTravelers = (userId, callback) => dispatch => {

  fetchWithDispatch(
    `/api/traveler/proxy_users/${userId}/authorized_travelers`,
    'GET',
    dispatch,
    AUTHORIZED_TRAVELERS_REQUEST,
    AUTHORIZED_TRAVELERS_SUCCESS,
    AUTHORIZED_TRAVELERS_FAILURE,
    null,
    callback
  );
};



export const getProxyPlans = (callback) => dispatch => {

  fetchWithDispatch(
    `/api/traveler/plans?proxy_plans=true`,
    'GET',
    dispatch,
    FETCH_PROXY_PLANS_REQUEST,
    FETCH_PROXY_PLANS_SUCCESS,
    FETCH_PROXY_PLANS_FAILURE,
    null,
    callback
  );
};


